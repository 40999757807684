<template>
  <div>
    <b-modal
      v-model="showLogoPopup"
      size="lg"
      centered
      hide-footer
      @hide="() => (showLogoPopup = false)"
    >
      <img :src="applicationLogo" class="card-img-top" alt="Application Logo" />
    </b-modal>
    <b-modal
      v-model="showVendorInfo"
      size="lg"
      centered
      hide-footer
      @hide="hidePopup"
    >
      <ApplicationInputs :data="selectedVendor" />
    </b-modal>
    <b-card
      :header="$i18n.t('navigation.applications')"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <!-- filters and search -->
      <FiltersAndSearch
        :id.sync="id"
        :searchKey.sync="searchKey"
        :sortOrder.sync="sortOrder"
        disableActiveOnly
      />
      <!-- end of filters and search -->
      <!-- table -->
      <b-table
        sticky-header
        :fields="columns"
        :items="applicationsList"
        class="shadow-sm rounded"
        small
        responsive
        hover
        bordered
        :busy="isTableBusy"
        empty-text="No matching records found"
        @row-clicked="onRowClick"
      >
        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.bottom="'Approve'"
            variant="flat-success"
            class="btn-icon"
            @click="onApprove(data.item.id)"
          >
            <feather-icon size="16" icon="CheckSquareIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.bottom="'Reject'"
            class="btn-icon"
            variant="flat-danger"
            @click="onReject(data.item.id)"
          >
            <feather-icon size="16" icon="XSquareIcon" />
          </b-button>
        </template>
      </b-table>
      <Pagination
        :fetch-data="getApplicationsList"
        :total-rows="totalRows"
        :take.sync="take"
        :skip.sync="skip"
      />
    </b-card>
  </div>
</template>

<script>
import { BTable, BButton, VBTooltip, BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import FiltersAndSearch from "@/views/components/FiltersAndSearch/FiltersAndSearch.vue";
import Pagination from "@/views/components/Pagination/Pagination.vue";
import store from "@/store/index";
import ApplicationInputs from "./ApplicationInputs.vue";

export default {
  components: {
    ApplicationInputs,
    BTable,
    FiltersAndSearch,
    BButton,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Pagination,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      applicationsList: null,
      isTableBusy: true,
      pageLength: 10,
      action: null,
      selectedVendor: {},
      showVendorInfo: false,
      showLogoPopup: false,
      applicationLogo: null,
      sortOrder: 2,
      id: "",
      searchKey: "",
      activeOnly: true,
      totalRows: 0,
      take: 20,
      skip: 0,
    };
  },
  computed: {
    ...mapState("vendorManagement", ["applications"]),
    tableTheme() {
      if (store.state.appConfig.layout.skin === "dark") {
        return "nocturnal";
      }
      return "";
    },
    applicationStatuses() {
      return {
        0: "Undefined",
        1: "Pending",
        2: "Rejected",
        3: "Approved",
        4: "Edited",
      };
    },
    columns() {
      return [
        {
          label: this.$t("users.id"),
          key: "id",
        },
        {
          label: this.$t("application.brand-name-geo"),
          key: "brandNameGeo",
        },
        {
          label: this.$t("application.brand-name-eng"),
          key: "brandNameEng",
        },
        {
          label: this.$t("application.status"),
          key: "applicationStatus",
          formatter: (value) => this.applicationStatuses[value],
        },
        {
          label: this.$t("application.contact-person-name"),
          key: "contactPerson",
        },
        {
          label: this.$t("application.legal-address"),
          key: "legalAddress",
        },
        {
          label: this.$t("application.contact-person-mobile"),
          key: "contactPersonMobile",
        },
        {
          label: this.$t("global.action"),
          key: "action",
        },
      ];
    },
  },
  watch: {
    searchKey() {
      this.getApplicationsList();
    },
    activeOnly() {
      this.getApplicationsList();
    },
    id() {
      this.getApplicationsList();
    },
    take() {
      this.getApplicationsList();
    },
    sortOrder() {
      this.getApplicationsList();
    },
  },
  created() {
    this.getApplicationsList();
  },
  methods: {
    ...mapActions("vendorManagement", [
      "setVendorApplicationStatus",
      "getApplications",
    ]),
    async getApplicationsList() {
      try {
        await this.getApplications({
          skip: this.skip,
          take: this.take,
          searchKey: this.searchKey,
          sortOrder: this.sortOrder,
          activeOnly: this.activeOnly,
          id: this.id,
        });
        this.applicationsList = this.applications.data.applications;
        this.isTableBusy = false;
      } catch (error) {
        // asdasd
        console.log(error);
      }
    },
    showLogo(logo) {
      this.showLogoPopup = true;
      this.applicationLogo = logo;
    },
    hidePopup() {
      this.showVendorInfo = false;
    },
    onRowClick(item) {
      console.log(item);
      this.selectedVendor = item;
      this.showVendorInfo = true;
    },
    toast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "FileIcon",
          variant: "success",
        },
      });
    },
    async onReject(applicationId) {
      this.isTableBusy = true;
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reject it!",
      });

      if (result.isConfirmed) {
        try {
          await this.setVendorApplicationStatus({
            id: applicationId,
            status: 2,
          });
          this.toast("Application successfully rejected.");
          this.getApplicationsList();
        } catch (err) {
          console.log(err);
        }
      }
      this.isTableBusy = false;
    },
    async onApprove(applicationId) {
      this.isTableBusy = true;

      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approve it!",
      });

      if (result.isConfirmed) {
        try {
          await this.setVendorApplicationStatus({
            id: applicationId,
            status: 3,
          });
          this.toast("Application successfully approved.");
          this.getApplicationsList();
        } catch (err) {
          console.log(err);
        }
      }
      this.isTableBusy = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}
.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}
.form-group {
  @media (max-width: 600px) {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
</style>
